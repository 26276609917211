<template>
    <div id="business">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <h1 class="wow fadeInUp">Transform Your Workplace<br><span>with Ingomu’s
                                                Coaching
                                                Solutions</span></h1>
                                        <li class="breadcrumb-item">Drive engagement, reduce stress, and boost
                                            productivity with scalable, affordable coaching and development tailored to your team’s
                                            unique needs.</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- About Us Section Start -->
        <div class="about-us">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- About Us Image Start -->
                        <div class="about-us-images">
                            <!-- About Us Img Start -->
                            <div class="about-us-img-1">
                                <figure class="image-anime">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-for-business-eric.png"
                                        alt="">
                                </figure>
                            </div>
                            <!-- About Us Img End -->

                            <!-- About Us Img Start -->
                            <div class="about-us-img-2">
                                <figure class="image-anime">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-for-business-2.png"
                                        alt="">
                                </figure>
                            </div>
                            <!-- About Us Img End -->

                            <!-- About Experience Box Start -->
                            <div class="about-experience-box">
                                <div class="about-experience-content">
                                    <img
                                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/BHL-2025-Ingomu-app-of-the-year.png">
                                </div>
                            </div>
                            <!-- About Experience Box End -->

                            <!-- Company Review Start -->
                            <div class="company-review">
                                <h3>COUNTLESS FIVE STAR REVIEWS</h3>
                            </div>
                            <!-- Company Review End -->
                        </div>
                        <!-- About Us Image End -->
                    </div>

                    <div class="col-lg-6" id="why">
                        <!-- About Us Content Start -->
                        <div class="about-us-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">WHY INGOMU FOR YOUR BUSINESS?</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.2s">Unlock Employee Potential <span>and Drive
                                        Success</span></h2>
                                <p class="wow fadeInUp" data-wow-delay="0.4s">In today’s fast-paced work environment,
                                    employee well-being and professional growth are critical to business success.
                                    Companies that invest in coaching and professional development report:
                                </p>
                            </div>
                            <!-- Section Title End -->

                            <!-- About Us List Start -->
                            <div class="about-us-list wow fadeInUp" data-wow-delay="0.6s">
                                <ul>
                                    <li>21% Higher Profitability</li>
                                    <li>20% Lower Turnover Rates</li>
                                    <li>40% Increase in Employee Engagement</li>
                                </ul>
                                <p class="wow fadeInUp mt-40" data-wow-delay="0.4s">Ingomu empowers your team with live
                                    virtual group coaching, on-demand resources, one-on-one coach communications, and an
                                    AI-powered coach assistant to foster a culture of growth, resilience, and innovation.
                                </p>
                            </div>
                            <!-- About Us List End -->

                            <!-- About Us Body Start -->
                            <div class="about-us-body">
                                <!-- About Company Client Start -->
                                <div class="about-comapny-client wow fadeInUp" data-wow-delay="0.8s">
                                    <div class="intro-video-box">
                                        <div class="video-play-button">
                                            <a href="https://www.youtube.com/watch?v=nYrQLRjJjGg" class="popup-video"
                                                data-cursor-text="Play">
                                                <i class="fa-solid fa-play"></i>
                                            </a>
                                            <p>watch video</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- About Company Client End -->
                            </div>
                            <!-- About Us Body End -->

                            <!-- About Us Footet Start -->
                            <div class="about-us-footer wow fadeInUp" data-wow-delay="1s">
                                <a href="https://calendar.app.google/DxVRDNezM5idW1p1A" target="_blank"
                                    class="btn-default textnone">Transform Your Workforce – Schedule a Free Demo
                                    Today!</a>
                            </div>
                            <!-- About Us Footet End -->
                        </div>
                        <!-- About Us Content End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- About Us Section End -->

        <!-- Our Service Start -->
        <div class="our-service bg-section" id="benefits">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <!-- Service Content Start -->
                        <div class="service-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">KEY BENEFITS OF INGOMU</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Empowering Teams <span>and Driving
                                        Business Success</span></h2>
                                <p class="wow fadeInUp" data-wow-delay="0.5s">Empower your workforce with Ingomu’s
                                    coaching and professional development solutions to boost engagement, reduce stress, enhance productivity, and
                                    foster a diverse, inclusive company culture.</p>
                            </div>
                            <!-- Section Title End -->

                            <!-- Service Btn Start -->
                            <div class="service-btn wow fadeInUp" data-wow-delay="0.75s">
                                <a href="https://calendar.app.google/DxVRDNezM5idW1p1A" target="_blank"
                                    class="btn-default textnone">Book a Call Today</a>
                            </div>
                            <!-- Service Btn End -->
                        </div>
                        <!-- Service Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Service Item List Start -->
                        <div class="service-item-list">
                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Boost Engagement</h3>
                                    <p>Equip your team with tools to stay motivated, collaborate, and achieve their
                                        goals.</p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="0.2s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Reduce Workplace Stress</h3>
                                    <p>Promote well-being with mindfulness practices and stress-management strategies.
                                    </p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="0.4s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Enhance Productivity</h3>
                                    <p>Employees with access to coaching and professional development are more focused, creative, and efficient.</p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="0.6s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Support Diversity and Inclusion</h3>
                                    <p>Tailored coaching helps bridge generational gaps, build emotional intelligence,
                                        and foster a positive company culture.</p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                        </div>
                        <!-- Service Item List End -->

                        <!-- Service Footer Start -->
                        <div class="service-footer wow fadeInUp" data-wow-delay="0.25s">
                            <p><span>free</span> <router-link :to="{ name: 'business', hash: '#contact-us' }"
                                    @click.native="scrollTo('#contact-us')" class="textnone">Send us a
                                    note</router-link> to
                                request your free quote.</p>
                        </div>
                        <!-- Service Footer Start -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Service End -->

        <!-- Page Services Start -->
        <div class="page-services" id="features">
            <div class="container">
                <div class="col-lg-6">
                    <!-- Section Title Start -->
                    <div class="section-title">
                        <h3 class="wow fadeInUp">Features</h3>
                        <h2 class="wow fadeInUp" data-wow-delay="0.2s">Empower Your Team with <span>Business-Focused
                                Features</span></h2>
                    </div>
                    <!-- Section Title End -->

                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <!-- Service Item Start -->
                        <div class="service-item wow fadeInUp">
                            <!-- Icon Box Start -->
                            <div class="icon-box">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                    alt="">
                            </div>
                            <!-- Icon Box End -->

                            <!-- Service Item Content Start -->
                            <div class="service-item-content">
                                <h3>Live Group Coaching</h3>
                                <p>Over 60 expert-led sessions monthly to address professional and personal growth,
                                    fostering skills and knowledge across teams.</p>
                            </div>
                            <!-- Service Item Content End -->
                        </div>
                        <!-- Service Item End -->
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <!-- Service Item Start -->
                        <div class="service-item wow fadeInUp" data-wow-delay="0.2s">
                            <!-- Icon Box Start -->
                            <div class="icon-box">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                    alt="">
                            </div>
                            <!-- Icon Box End -->

                            <!-- Service Item Content Start -->
                            <div class="service-item-content">
                                <h3>AI Coach Assistant, Eric</h3>
                                <p>On-demand guidance tailored to employee goals and challenges, providing personalized
                                    recommendations for maximum impact.</p>
                            </div>
                            <!-- Service Item Content End -->
                        </div>
                        <!-- Service Item End -->
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <!-- Service Item Start -->
                        <div class="service-item wow fadeInUp" data-wow-delay="0.4s">
                            <!-- Icon Box Start -->
                            <div class="icon-box">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                    alt="">
                            </div>
                            <!-- Icon Box End -->

                            <!-- Service Item Content Start -->
                            <div class="service-item-content">
                                <h3>On-Demand Resources</h3>
                                <p>Instant access to 1,000+ sessions for continuous learning and growth, available
                                    anytime to suit employees' schedules.</p>
                            </div>
                            <!-- Service Item Content End -->
                        </div>
                        <!-- Service Item End -->
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <!-- Service Item Start -->
                        <div class="service-item wow fadeInUp" data-wow-delay="0.6s">
                            <!-- Icon Box Start -->
                            <div class="icon-box">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                    alt="">
                            </div>
                            <!-- Icon Box End -->

                            <!-- Service Item Content Start -->
                            <div class="service-item-content">
                                <h3>Personalized Development</h3>
                                <p>Progress tracking and recognition with gamified badges to motivate employees and
                                    celebrate achievements.</p>
                            </div>
                            <!-- Service Item Content End -->
                        </div>
                        <!-- Service Item End -->
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <!-- Service Item Start -->
                        <div class="service-item wow fadeInUp" data-wow-delay="0.8s">
                            <!-- Icon Box Start -->
                            <div class="icon-box">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                    alt="">
                            </div>
                            <!-- Icon Box End -->

                            <!-- Service Item Content Start -->
                            <div class="service-item-content">
                                <h3>Community Engagement</h3>
                                <p>A social feed to connect users, promoting collaboration and shared learning
                                    experiences.</p>
                            </div>
                            <!-- Service Item Content End -->
                        </div>
                        <!-- Service Item End -->
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <!-- Service Item Start -->
                        <div class="service-item wow fadeInUp" data-wow-delay="1s">
                            <!-- Icon Box Start -->
                            <div class="icon-box">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                    alt="">
                            </div>
                            <!-- Icon Box End -->

                            <!-- Service Item Content Start -->
                            <div class="service-item-content">
                                <h3>Retention and Engagement Insights</h3>
                                <p>Analytics tools to track employee participation and engagement trends, enabling HR
                                    teams to measure success and refine initiatives effectively.</p>
                            </div>
                            <!-- Service Item Content End -->
                        </div>
                        <!-- Service Item End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Services End -->

        <!-- Company Growth Section Start -->
        <div class="company-growth bg-section mb-60" id="roi">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <!-- Company Growth Image Start -->
                        <div class="company-growth-image">
                            <figure class="image-anime reveal">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/unlock-your-potential-with-ingomu.png"
                                    alt="">
                            </figure>
                        </div>
                        <!-- Company Growth Image End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Company Growth Content Start -->
                        <div class="company-growth-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">ROI</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Transform Employee
                                    Well-Being<br><span>and Business Success with Ingomu</span></h2>
                                <p class="wow fadeInUp" data-wow-delay="0.5s"><i>"Employees who feel supported are 3x
                                        more likely to stay with their company."</i> Investing in coaching and development creates a
                                    healthier, happier, and more productive workforce. With Ingomu, you’re not just
                                    offering resources—you’re building a stronger future for your organization.</p>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s">Discover how Ingomu delivers
                                    measurable
                                    ROI by improving employee well-being, boosting profitability, and addressing key HR
                                    challenges with cost-effective, customizable coaching and professional development solutions. For added impact,
                                    we also provide the option to bring coaches onsite for personalized, in-person
                                    support.</p>
                            </div>
                            <!-- Section Title End -->

                            <!-- Company Growth Button Start -->
                            <div class="company-growth-btn wow fadeInUp col-lg-12 row" data-wow-delay="0.75s">
                                <div class="col-md-4 mb-3 ">
                                    <a href="https://calendar.app.google/DxVRDNezM5idW1p1A" target="_blank"
                                        class="btn-default textnone">Book a Call Today</a>
                                </div>
                                <div class="col-md-8 mb-3 ">
                                    <div class="intro-video-box">
                                        <div class="video-play-button">
                                            <a href="https://www.youtube.com/watch?v=CkVA6baU5eo" class="popup-video"
                                                data-cursor-text="Play">
                                                <i class="fa-solid fa-play"></i>
                                            </a>
                                            <p class="text-black">See How It Works</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Company Growth Button End -->
                        </div>
                        <!-- Company Growth Content End -->
                    </div>

                    <div class="col-lg-12">
                        <!-- Company Growth Box Start -->
                        <div class="company-growth-box">
                            <!-- Company Growth Item Start -->
                            <div class="company-growth-item">
                                <h3>70%+ Increase in Well-Being</h3>
                                <p>Employees report significant improvements after just one live session.</p>
                            </div>
                            <!-- Company Growth Item End -->

                            <!-- Company Growth Item Start -->
                            <div class="company-growth-item">
                                <h3>Boost Profitability</h3>
                                <p>Engaged employees drive 21% higher profitability for companies.</p>
                            </div>
                            <!-- Company Growth Item End -->

                            <!-- Company Growth Item Start -->
                            <div class="company-growth-item">
                                <h3>Cost-Effective Solutions</h3>
                                <p>Affordable subscriptions tailored for companies of all sizes.</p>
                            </div>
                            <!-- Company Growth Item End -->

                            <!-- Company Growth Item Start -->
                            <div class="company-growth-item">
                                <h3>Customizable Programs</h3>
                                <p>Address HR challenges like absenteeism, retention, and employee satisfaction.</p>
                            </div>
                            <!-- Company Growth Item End -->
                        </div>
                        <!-- Company Growth Box End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Company Growth Section End -->

        <!-- Our Testimonial Section Start -->
        <div class="our-testimonial bg-section" id="testimonials">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-8">
                        <div class="our-testimonial-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">Testimonials</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Success Stories
                                    <span>from Our Users</span>
                                </h2>
                            </div>
                            <!-- Section Title End -->
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <!-- Testimonial Slider Start -->
                        <div class="testimonial-slider">
                            <div class="swiper">
                                <div class="swiper-wrapper" data-cursor-text="Drag">
                                    <!-- Testimonial Slide Start -->
                                    <div class="swiper-slide" v-for="(testimony, index) in testimonialsList"
                                        :key="index">
                                        <div class="testimonial-item">
                                            <!-- <div class="testimonial-slider-image">
                                                <figure class="image-anime">
                                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu_Logo.png" alt="">
                                                </figure>
                                            </div> -->

                                            <div class="testimonial-slider-content">
                                                <div class="testimonial-header">
                                                    <div class="testimonial-rating-company">
                                                        <img src="images/icon-testimonial-rating-4.svg" alt="">
                                                    </div>
                                                    <div class="testimonial-quote">
                                                        <img src="images/testimonial-quote.svg" alt="">
                                                    </div>
                                                </div>
                                                <div class="testimonial-rating">
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                </div>
                                                <div class="testimonial-content">
                                                    <p>{{ testimony.comment }}</p>
                                                </div>
                                                <div class="author-content">
                                                    <h3>{{ testimony.name }}</h3>
                                                    <p>{{ testimony.title }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Testimonial Slide End -->
                                </div>
                                <div class="testimonial-btn">
                                    <div class="testimonial-button-prev"></div>
                                    <div class="testimonial-button-next"></div>
                                </div>
                            </div>
                        </div>
                        <!-- Testimonial Slider End -->
                    </div>

                </div>
            </div>
        </div>
        <!-- Our Testimonial Section End -->

        <BlogSection />

        <!-- Our FAQs Section Start -->
        <div class="our-faqs bg-section mt-100" id="faq">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <!-- Our FAQs Content Start -->
                        <div class="our-faqs-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">FAQ</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Answers to<br><span>Your
                                        Questions</span></h2>
                            </div>
                            <!-- Section Title End -->

                            <!-- FAQs CTA Box Start -->
                            <div class="faqs-cta-box wow fadeInUp" data-wow-delay="0.75s">
                                <div class="faqs-cta-content">
                                    <h3>Do you still have questions?</h3>
                                    <p>We're ready to help!</p>
                                    <router-link :to="{ name: 'business', hash: '#contact-us' }"
                                        @click.native="scrollTo('#contact-us')" class="btn-default mr-20">Contact
                                        Us</router-link>
                                    <a href="https://calendar.app.google/DxVRDNezM5idW1p1A"
                                        class="btn-default textnone">Book a Call Today</a>
                                </div>
                            </div>
                            <!-- FAQs CTA Box End -->
                        </div>
                        <!-- Our FAQs Content End -->
                    </div>

                    <div class="col-lg-6">
                        <div class="our-faq-section">
                            <!-- FAQ Accordion Start -->
                            <div class="faq-accordion" id="faqaccordion">
                                <!-- FAQ Item Start -->
                                <div class="accordion-item wow fadeInUp" v-for="(faq, index) in faqGeneral"
                                    :key="index">
                                    <h2 class="accordion-header" :id="faq.id">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" :data-bs-target="faq.hash" aria-expanded="true"
                                            :aria-controls="faq.controls">
                                            {{ faq.question }}
                                        </button>
                                    </h2>
                                    <div :id="faq.controls" class="accordion-collapse collapse"
                                        :aria-labelledby="faq.id" data-bs-parent="#faqaccordion">
                                        <div class="accordion-body">
                                            <p v-html="faq.answer"></p>
                                        </div>
                                    </div>
                                </div>
                                <!-- FAQ Item End -->
                            </div>
                            <!-- FAQ Accordion End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Our FAQs Section End -->

        <!-- Page Contact Us Start -->
        <div class="page-contact-us" id="contact-us">
            <div class="container">
                <div class="row section-row align-items-center">
                    <div class="col-lg-7 col-md-9">
                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h3 class="wow fadeInUp">Contact Us</h3>
                            <h2 class="wow fadeInUp" data-wow-delay="0.25s">Let's Connect<br><span>and Grow</span></h2>
                        </div>
                        <!-- Section Title End -->
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- Contact Us Form Start -->
                        <div class="contact-us-form">
                            <!-- Contact Us Title Start -->
                            <div class="contact-us-title">
                                <h3 class="wow fadeInUp textnone">Send a Message</h3>
                                <p class="wow fadeInUp" data-wow-delay="0.2s">
                                    By submitting this form, you consent to be contacted at the email address provided.
                                    You may opt out of communications at any time by following the instructions included
                                    in our emails. Your information will be kept strictly confidential and will not be
                                    shared, sold, or used for purposes other than those stated. For more details, please
                                    refer to our <router-link :to="{ name: 'terms' }">Privacy Policy</router-link>.
                                </p>
                            </div>
                            <!-- Contact Us Title End -->

                            <!-- Contact Us Form Start -->
                            <form id="contactForm" action="#" method="POST" data-toggle="validator" class="wow fadeInUp"
                                data-wow-delay="0.4s">
                                <div class="row">
                                    <div class="form-group col-lg-12 mb-4">
                                        <input type="text" name="fname" class="form-control" id="fname"
                                            placeholder="Name" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <!-- <div class="form-group col-md-6 mb-4">
                                        <input type="text" name="lname" class="form-control" id="lname"
                                            placeholder="Last Name" required>
                                        <div class="help-block with-errors"></div>
                                    </div> -->

                                    <div class="form-group col-lg-12 mb-4">
                                        <input type="email" name="email" class="form-control" id="email"
                                            placeholder="Email Address" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <!-- <div class="form-group col-md-6 mb-4">
                                        <input type="text" name="phone" class="form-control" id="phone"
                                            placeholder="Phone Number" required>
                                        <div class="help-block with-errors"></div>
                                    </div> -->

                                    <div class="form-group col-md-12 mb-5">
                                        <textarea name="message" class="form-control" id="message" rows="4"
                                            placeholder="Message" required></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="contact-form-btn">
                                            <button type="submit" class="btn-default mb-25">submit message</button>
                                            <div id="msgSubmit" class="h3 hidden"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- Contact Us Form End -->
                        </div>
                        <!-- Contact Us Form End -->
                    </div>

                    <div class="col-lg-6">
                        <!-- Contact Information Start -->
                        <div class="contact-information">
                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp">

                                <!-- Footer Contact Information Start -->
                                <div class="contact-info-contant">
                                    <h3>Connect With Us</h3>
                                </div>
                                <!-- Footer Contact Information End -->

                                <!-- Footer Social Link Start -->
                                <!-- Post Social Links Start -->
                                <div class="post-social-sharing wow fadeInUp" data-wow-delay="0.5s">
                                    <ul>
                                        <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                                    class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-instagram"></i></a></li>
                                        <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                                    class="fa-brands fa-youtube"></i></a></li>
                                        <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.pinterest.com/ingomu_coaching/"><i
                                                    class="fa-brands fa-pinterest"></i></a></li>
                                    </ul>
                                </div>
                                <!-- Post Social Links End -->
                                <!-- Footer Social Link End -->
                            </div>
                            <!-- Contact Info Item End -->


                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
                                <!-- Contact Info Contact Start -->
                                <div class="contact-info-contant">
                                    <h3>Get The App</h3>
                                </div>
                                <!-- Contact Info Contact End -->

                                <!-- Contact Info Body Start -->
                                <div class="contact-info-body">
                                    <div class="col-lg-12 row">
                                        <div class="col-lg-12 col-12 mb-3">
                                            <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                                    alt="" class="h-50 mr-20">
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                                target="_blank">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                                    alt="" class="h-50">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Contact Info Body End -->
                            </div>
                            <!-- Contact Info Item End -->

                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
                                <!-- Contact Info Contact Start -->
                                <div class="contact-info-contant">
                                    <a href="https://calendar.app.google/DxVRDNezM5idW1p1A" target="_blank"
                                        class="btn-default textnone">Book a Call Today</a>
                                </div>
                                <!-- Contact Info Contact End -->

                            </div>
                            <!-- Contact Info Item End -->

                        </div>
                        <!-- Contact Information End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Contact Us End -->

        <!-- EBook modal Start -->
        <EBook />
        <!-- EBook modal End -->

        <!-- Footer Start -->
        <footer class="main-footer bg-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6">
                        <!-- About Footer Start -->
                        <div class="about-footer">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h2 class="fs-35">What you do today<br><span>can improve all your tomorrows.</span></h2>
                                <p class="text-white">- Ralph Martson</p>
                            </div>
                            <!-- Section Title End -->
                        </div>
                        <!-- About Footer End -->
                    </div>

                    <div class="col-lg-3 col-md-3">
                        <!-- Footer Contact Information Start -->
                        <div class="footer-contact-info">
                            <h3>Connect With Us</h3>
                        </div>
                        <!-- Footer Contact Information End -->

                        <!-- Footer Social Link Start -->
                        <div class="footer-social-links">
                            <ul>
                                <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                            class="fa-brands fa-linkedin" target="_blank"></i></a></li>
                                <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i
                                            class="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                            class="fa-brands fa-youtube" target="_blank"></i></a></li>
                                <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i
                                            class="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://www.pinterest.com/ingomu_coaching/" target="_blank"><i
                                            class="fa-brands fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <!-- Footer Social Link End -->
                    </div>

                    <div class="col-lg-2 col-md-3">
                        <!-- Footer Contact Information Start -->
                        <div class="footer-contact-info">
                            <h3>say hello</h3>
                            <p><a href="mailto:contact@ingomu.com?subject=Website%20Email%20Message"
                                    class="text-white">Contact@Ingomu.com</a></p>
                        </div>
                        <!-- Footer Contact Information End -->
                    </div>
                </div>

                <!-- Footer Copyright Section Start -->
                <div class="footer-copyright">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <!-- Footer Menu Start -->
                            <div class="footer-menu">
                                <ul>
                                    <li><router-link :to="{ name: 'home', hash: '#home' }"
                                            @click.native="scrollTo('home')">Home</router-link></li>
                                    <li><router-link
                                            :to="{ name: 'blogtags', params: { tag: 'Career opportunities' } }">Careers</router-link>
                                    </li>
                                    <li><router-link :to="{ name: 'terms' }">Terms & Privacy</router-link></li>
                                    <li><router-link :to="{ name: 'business', hash: '#contact-us' }"
                                            @click.native="scrollTo('#contact-us')">Contact</router-link></li>
                                    <li><a href="https://www.bonfire.com/store/ingomu/" target="_blank">Store</a></li>
                                </ul>
                            </div>
                            <!-- Footer Menu End -->
                        </div>

                        <div class="col-md-6">
                            <!-- Footer Copyright Start -->
                            <div class="footer-copyright-text">
                                <p>Copyright © {{ currentDate.getFullYear() }} Ingomu Learning LLC. All Rights Reserved.
                                </p>
                            </div>
                            <!-- Footer Copyright End -->
                        </div>
                    </div>
                </div>
                <!-- Footer Copyright Section End -->
            </div>
        </footer>
        <!-- Footer End -->
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineBusinessHeader";
import BlogSection from "@/components/BlogBusinessSection";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import { testimonal } from "../../../mixins/testimonial";
import { validator } from "../../../mixins/validator";
import EBook from "../../../components/EBook.vue";
import postscribe from "postscribe";
import Swal from "sweetalert2";
import posthog from 'posthog-js';

const TIMEOUT = 1000;

export default {
    name: "businessv4",
    metaInfo() {
        return {
            title: "Transform Your Workplace with Scalable Coaching Solutions | Ingomu for Business | Ingomu App",
        }
    },
    components: {
        SiteHeader,
        EBook,
        BlogSection
    },
    data() {
        return {
            currentDate: new Date,
            testimonialsList: [
                {
                    name: "Aiden",
                    title: "Ingomu Subscriber",
                    comment: "I've been going through a tough time at work and it's been spilling over into my home life. I started using the app a while back and I've found so much support in the communities. They've really helped me come up with strategies to tackle the challenges, both at work and at home. They’ve boosted my confidence and helped me improve my relationships with my colleagues and family. I'm thankful for the impact this app has had on my life and career."
                },
                {
                    name: "Anastasia S.",
                    title: "Ingomu Subscriber",
                    comment: "Where else can you get affordable access to unlimited coaching when you need it? I’ve been able to control my anxiety by joining live sessions. I’m less stressed and more focused! Thanks."
                },
                {
                    name: "Caitlin",
                    title: "Ingomu Subscriber",
                    comment: "I'm a full-time working mom of two and let me tell you, life has been INSANE lately. Work stress, the kids, trying to keep it all together...it was seriously making me lose my mind! I was feeling so overwhelmed and anxious, I didn't think I could handle it anymore. Then I heard about this Ingomu coaching app and I was like, \"Why not?\" Worst case scenario, it's a waste of time, right? But OMG, it's been a total game-changer! The sessions have helped me so much with managing stress, finding balance, and actually being present for my family and work. I'm not gonna lie, it's kinda amazing how much better I feel. If you're struggling like I was, this app is 100% worth it."
                },
                {
                    name: "Chris W.",
                    title: "Ingomu Subscriber",
                    comment: "Life can be difficult to navigate for everyone. Life coaching is tremendously helpful for overcoming the challenges we all face, but historically it’s only been affordable for the well-off. Ingomu changes the game. It brings amazing coaches, covering a broad range of life, work, health, and spiritual topics, to the masses, at an incredibly reasonable price."
                },
                {
                    name: "Sara M.",
                    title: "Ingomu Subscriber",
                    comment: "Ingomu has completely transformed how I approach personal development. The live coaching sessions are incredible. I’ve gained so much clarity in my career and well-being. Worth every penny!"
                },
                {
                    name: "Marco R.",
                    title: "Ingomu Subscriber",
                    comment: "As a manager, I wanted to grow my leadership skills, and Ingomu has been a game-changer. The variety of expert coaches and on-demand sessions make it easy to fit into my schedule. Plus, the community and badges keep me motivated!"
                },
                {
                    name: "Lisa T.",
                    title: "HR Director",
                    comment: "We introduced Ingomu to our employees and the impact has been incredible. Our team is more engaged, focused, and proactive about personal growth. It’s an invaluable tool for any company looking to invest in their people."
                }
            ],
            faqGeneral: [
                {
                    id: "headingOne",
                    controls: "collapseOne",
                    hash: "#collapseOne",
                    question: "Are Ingomu coaches vetted?",
                    answer: "Yes, all coaches undergo a stringent vetting process, including verification of coaching experience and relevant certifications."
                },
                {
                    id: "headingTwo",
                    controls: "collapseTwo",
                    hash: "#collapseTwo",
                    question: "How much does it cost?",
                    answer: "Ingomu delivers unmatched value for your team with affordable, scalable pricing tailored to your workforce size."
                },
                {
                    id: "headingThree",
                    controls: "collapseThree",
                    hash: "#collapseThree",
                    question: "Is there an access limit to coaches and features for my employees?",
                    answer: "No, employees have unlimited access to all coaches, live virtual group sessions, and hundreds of on-demand recordings. One-on-one messaging and engagement between sessions are also included. They can get the help when needed most."
                },
                {
                    id: "headingFour",
                    controls: "collapseFour",
                    hash: "#collapseFour",
                    question: "Is employee information private?",
                    answer: "Yes, we take privacy seriously. Only employees’ first names are shared with coaches, and all personal data remains strictly confidential."
                },
                {
                    id: "headingFive",
                    controls: "collapseFive",
                    hash: "#collapseFive",
                    question: "How do I know if Ingomu works for my employees?",
                    answer: "We provide monthly reports on platform usage, highlighting key focus areas and progress. Data is anonymous, ensuring individual privacy. Custom reporting is available upon request. On average, users self-report an increase in well-being of 70% after participating in a session."
                },
                {
                    id: "headingSix",
                    controls: "collapseSix",
                    hash: "#collapseSix",
                    question: "How long does it take to implement Ingomu?",
                    answer: "Implementation is quick and easy—no integrations required. Your employees can start benefiting from the app within a day."
                },
                {
                    id: "headingSeven",
                    controls: "collapseSeven",
                    hash: "#collapseSeven",
                    question: "Can Ingomu integrate with other platforms?",
                    answer: "While no integrations are required, custom solutions may be available upon request."
                },
                {
                    id: "headingEight",
                    controls: "collapseEight",
                    hash: "#collapseEight",
                    question: "How do the app’s features work?",
                    answer: "We’ve created a helpful guide with short videos to help you get started. Find it <a href=\"https://ingomu.com/welcome\">here.<\/a>"
                },
                {
                    id: "headingNine",
                    controls: "collapseNine",
                    hash: "#collapseNine",
                    question: "What does support and technical assistance look like?",
                    answer: "We provide marketing materials and guidance for successful employee onboarding.Our app is user-friendly, and we provide technical support via the Help menu, weekly drop-in sessions, and a dedicated landing page for your company or institution. You will also be assigned a Success Coach to support our partnership."
                },
                {
                    id: "headingTen",
                    controls: "collapseTen",
                    hash: "#collapseTen",
                    question: "On-site and virtual coaching/speaking engagements.",
                    answer: "We offer tailored solutions, including virtual or on-site coaching, workshops, facilitation, and keynote speaking. <a href=\"https://ingomu.com/speaking\">Find more details here.<\/a>"
                },
                {
                    id: "headingEleven",
                    controls: "collapseEleven",
                    hash: "#collapseEleven",
                    question: "I would like to speak with someone.",
                    answer: "<a href=\"https://ingomu.com/contact\">Click here<\/a> to send us a message<\/router-link>, and a team member will respond promptly. <a href=\"https://calendar.app.google/DxVRDNezM5idW1p1A\" target=\"_blank\">Or, get on our calendar here.<\/a>"
                },
            ]
        }
    },
    methods: {
        ...mapActions(["contactUs"]),
        submit(name, email, message) {
            var _this = this;
            _this.contactUs({
                name: name,
                email: email,
                message: message,
                page: "Business"
            });
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 25).join(" ")
        },
        imageLink(url) {
            // return unescape(url.substring(0, url.indexOf("&token=")))
            return unescape(url.substring(0, url.indexOf("&token=")))
        },
        scrollTo(hashtag) {
            document.getElementById(hashtag.slice(1)).scrollIntoView()
        },
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        postscribe(
            "#business",
            `<script type="text/javascript">
                var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/5c8934e5c37db86fcfcd99d4/default';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
                })();
                <\/script>`
        );
        posthog.capture('$pageview')

        var $window = $(window);
        var $this = this;

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            $('.popup-video').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: true
            });

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()

            // Testimonial
            testimonal()

            validator((contactForm) => {
                let name = contactForm[0]["value"];
                let email = contactForm[1]["value"];
                let message = contactForm[2]["value"];
                posthog.capture('contactus_business', {
                    'date': new Date().toString(),
                    'email': this.email,
                    'name': this.name
                });
                $this.submit(name, email, message);
                Swal.fire({
                        title: "",
                        text: "Thank You. Someone will be in contact with you shortly.",
                        icon: "success",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
            })
        })
    },
    beforeDestroy() {
        posthog.capture('$pageleave')
    },
}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-for-business.png);
}

.mb-25 {
    margin-bottom: 25px;
}

.page-header-box ol li.breadcrumb-item {
    text-transform: none;
}

.mt-40 {
    margin-top: 40px;
}

.mb-60 {
    margin-bottom: 60px;
}

.video-play-button {
    justify-content: start;
}

.video-play-button p {
    color: black;
}

.company-review h3 {
    font-size: 14px;
}

.company-growth-content .section-title h2 {
    max-width: none;
}

.testimonial-slider-content,
.testimonial-slider-image {
    width: 100%;
}

.blog-item .blog-featured-image a {
    cursor: pointer;
}

.our-faqs {
    background: var(--white-color);
    padding: 100px 0;
}

.mr-20 {
    margin-right: 20px;
}

.company-growth-item h3 {
    text-transform: none;
}

.textnone {
    text-transform: none;
}

.mt-16 {
    margin-top: 16px;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-for-business-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>